.share {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  top: 0;

  a {
    border: none;
    text-decoration: none;
  }
}

.share__title {
  opacity: 1;
  transition: opacity 300ms;
  font-size: 0.9rem;
  text-transform: uppercase;
  vertical-align: middle;

  .share:hover & {
    opacity: 0;
  }
}

.share__list {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 8rem;

  li {
    display: inline-block;
    font-size: 1.7rem;
    margin: 0;
    opacity: 0;
    transform: translateX(-100px);
    transition:
      opacity 300ms,
      transform 300ms;

    .share:hover & {
      opacity: 1;
      transform: translateX(0);

      &:nth-child(1) {
        transition-delay: 200ms;
      }

      &:nth-child(2) {
        transition-delay: 250ms;
      }

      &:nth-child(3) {
        transition-delay: 300ms;
      }

      &:nth-child(4) {
        transition-delay: 350ms;
      }
    }
  }
}
