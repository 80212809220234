.template-two-columns {
  font-size: 0;

  margin: 1rem 0 1.5rem 0;

  @include breakpoint-from($min-width-small) {
    margin: 2rem 0 2.5rem 0;
  }

  @include breakpoint-from($min-width-medium) {
    margin: 3rem 0 4rem 0;
  }


  > a,
  > span,
  > blockquote {
    border: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 49%;
  }

  > a {
    display: none;
  }

  blockquote {
    font-size: 2rem;

    &:before {
      content: "“";
    }

    &:after {
      content: "”";
    }
  }
}

.template-two-columns__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  padding: 74% 0 0 0;
  position: relative;
  vertical-align: middle;
  margin: 0 2% 0 0;
  width: 49%;

  .sloth__content {
    @include stretch;
    position: absolute !important;
  }

  &:nth-of-type(2) {
    margin: 0;
  }
}

.template-two-columns__caption {
  @extend .caption;
  margin: 0;
  padding: 2em 0 1em 0;
  font-size: 1.2rem;
}
