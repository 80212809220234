.btn {
  appearance: none;
  background-color: $black;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  font-family: $sans;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.375em;
  padding: 0.5em 1.5em;
  text-align: center;
  text-decoration: none;
  outline: 0;
  vertical-align: middle;

  &:hover,
  &:focus {
    background-color: $black;
  }
}
