.fixed-summary {
  background-color: white;
  bottom: 0;
  height: 50px;
  left: 0;
  position: fixed;
  right: 0;
  transform: translate(0, 53px);
  transition: transform 384ms $ease-in-out-expo;
  z-index: 100;
  width: 100vw;

  .is-scrolling-down &.is-open,
  &.is-open:hover {
    transform: translate(0, 0);
  }
}

.fixed-summary__reading-progress {
  background-color: gray(100, 0.5);
  height: 3px;
  left: 0;
  position: absolute;
  top: -3px;
  transition: width 100ms;
}

.fixed-summary__logo {
  @extend .coffeeklatch-logo;
  display: block;
  font-size: 1rem;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);

  @include breakpoint-up-to($min-width-small) {
    display: none;
  }
}

.fixed-summary__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &:before {
    content: 'you are reading';
    display: inline-block;
  }

  @include breakpoint-up-to($min-width-small) {
    width: 100vw;

    &:before {
      content: '';
    }
  }
}

.fixed-summary__content__title {
  border-bottom: none;
  display: inline-block;
  font-size: 1.2rem;
  margin: 0 0 0 0.5em;
  letter-spacing: 1px;
  text-decoration: none;
}

.fixed-summary__share {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);

  @include breakpoint-up-to($min-width-small) {
    right: 0;
  }
}
