.grid-post-item {
  border-bottom: none;
  display: inline-block;
  margin: 0 25px 25px 0;
  width: 100%;

  &:hover {
    color: $black;

    .grid-post-item__poster__inner {
      opacity: 1;
    }

    // sloth start
    //
    // utilize blendmode
    .sloth__background {
      opacity: .4;
      filter: grayscale(100%);
      mix-blend-mode: multiply;
    }
    // sloth end
  }
}

//
// Poster
//
.grid-post-item__poster {
  background-color: rgba(0,0,0,.05);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  padding-top: 86%; // 260px / 300px = 0.86 -> ratio
  position: relative;
  margin: 0;
  vertical-align: middle;

  .sloth__content {
    position: static !important;
  }
}

.grid-post-item__poster__inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  //transition: opacity 150ms $ease-in-out-quad;
  z-index: 3;
  width: 100%;

  &.is_dark {
    color: white;
  }

  @include breakpoint-up-to($min-width-xsmall) {
    display: none;
  }
}

//
// Title
//
.grid-post-item__title {
  box-sizing: border-box;
  font-size: $em-base + 12px;
  font-weight: bold;
  line-height: $em-base + 15px;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 70%;
  text-align: center;
}

//
// CTA
//
.grid-post-item__cta {
  text-decoration: underline;
  font-size: $em-base + 2px;
  line-height: normal;
  margin: 20px 0 0 0;
  font-weight: normal;
  text-align: center;
}

//
// Description
//
.grid-post-item__description {
  text-align: center;
}
