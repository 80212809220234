// Scrolling shouldn't happen on the html / body
// Force it to full width
html,
body {
  cursor: default;
  height: 100%;
  min-height: 100%;
  position: relative;
}

// Wrap the site in a div that has disabled scrolling
.site-container {
  height: 100%;
  overflow: hidden;
  position: relative;

  @media only all and (max-width: $mobile-navigation-breakpoint - 1) {
    background-color: #fff; // The background you'd want to give to the body element - DON'T make transparent
    z-index: 20;
  }
}

// Fixed element contains a native, app like fixed header on mobile
.site-container__fixed {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;

  @media only all and (max-width: $mobile-navigation-breakpoint - 1) {
    background-color: #fff;
    height: $mobile-navigation-fixed-height;
  }
}

// Default toggle styles
.site-container__mobile-navigation-toggle {
  border: 0;
  bottom: 0;
  display: block;
  line-height: $mobile-navigation-fixed-height;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  text-decoration: none;
  width: $mobile-navigation-fixed-height;

  &:hover,
  &:visited {
    border: 0;
  }

  @media only all and (min-width: $mobile-navigation-breakpoint) { display: none; }
}

// Scrollable area is the content div that is, you guessed it, scrollable
.site-container__scrollable {
  box-sizing: border-box;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding: 130px 0 0 0;

  @media only all and (max-width: $mobile-navigation-breakpoint - 1) {
    padding-top: $mobile-navigation-fixed-height;
    position: relative;
    z-index: 10;
  }
}

// The mobile navigation is positioned fixed underneath the website (.site-container)
.mobile-navigation {
  background-color: #ddd;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 60px;
  transform: translate3d(0, -100%, 0);
  z-index: 30;

  .show-mobile-navigation & {
    transform: translate3d(0, 0, 0);
  }

  @include breakpoint-from($min-width-small) {
    top: 100px;
  }

  @media only all and (min-width: $mobile-navigation-breakpoint) {
    display: none;
  }
}

// Multilevel support, all levels are specified by this container div
.mobile-navigation__level {
  float: left;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

// Set CSS transitions for the mobile navigation animation
.site-container,
.mobile-navigation {
  @media only all and (max-width: $mobile-navigation-breakpoint - 1px) {
    transition: transform $mobile-navigation-animation-time $mobile-navigation-animation-easing;
  }
}

// .show-mobile-navigation is a class that gets toggled on the body to show the navigation
.show-mobile-navigation {
  overflow: hidden;

  .site-container {
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  }
}

// For all levels, create the corresponding [data-mobile-navigation-level]
// so the navigation knows how much pixels to animate
@if $mobile-navigation-levels > 1 {
  @for $i from 2 through $mobile-navigation-levels {
    [data-mobile-navigation-level="#{$i}"] {
      .mobile-navigation {
        transform: translate3d(-100% / $mobile-navigation-levels * ($i - 1), 0, 0);
      }
    }
  }
}
