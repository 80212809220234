.spread {
	box-sizing: border-box;
	margin: -104px 0 0 0;
	width: 100%;

	@include breakpoint-from($min_width_medium) {
		box-sizing: border-box;
		height: 100vh;
	}
}

.spread_column {
	position: relative;

	@include breakpoint-from($min_width_medium) {
		float: left;
		position: relative;
		height: inherit;
		width: 50%;
	}

	&:first-child {
		@include breakpoint-up-to($min-width-medium) {
			height: 102vh;
		}
	}
}

.spread_column-image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: inherit;
}

.spread_column-centeredtext {
	display: none;
	height: auto;
	text-align: center;
	margin: 0 auto;
	width: 75%;

	&.on-image{
		color: white;
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-75%);
		width: 70%;
		z-index: 50;

		.the-sound-of-c-one {
			display: none;
		}
	}

	@include breakpoint-from($min_width_medium) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		&.on-image {
			display: none;
		}
	}

	@include breakpoint-from($min_width_medium) {
		display: block;
		width: 75%;
	}
	@include breakpoint-from($min_width_xlarge) {
		width: 50%;
	}
}
