


// Main font
// LL Circular
//
@font-face {
    font-family: "Circular-Book";
    src:url("/public/assets/fonts/circular/lineto-circular-book.eot");
    src:url("/public/assets/fonts/circular/lineto-circular-book.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/circular/lineto-circular-book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}


// Name fonts
// Sectra
//
@font-face {
    font-family: "Sectra";
    src:url("/public/assets/fonts/sectra/GT-Sectra-Bold.eot");
    src:url("/public/assets/fonts/sectra/GT-Sectra-Bold.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/sectra/GT-Sectra-Bold.woff") format("woff"),
        url("/public/assets/fonts/sectra/GT-Sectra-Bold.ttf")  format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Falster-grotesk
//
@font-face {
    font-family: "Falter-Grotesk";
    src:url("/public/assets/fonts/falster-grotesk/bc-falster-grotesk-bold.eot");
    src:url("/public/assets/fonts/falster-grotesk/bc-falster-grotesk-bold.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/falster-grotesk/bc-falster-grotesk-bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

// Superior
//
@font-face {
    font-family: "SuperiorTitle-Bold";
    src:url("/public/assets/fonts/superior/SuperiorTitle-Bold.eot");
    src:url("/public/assets/fonts/superior/SuperiorTitle-Bold.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/superior/SuperiorTitle-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "SuperiorTitle-BoldItalic";
    src:url("/public/assets/fonts/superior/SuperiorTitle-BoldItalic.eot");
    src:url("/public/assets/fonts/superior/SuperiorTitle-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/superior/SuperiorTitle-BoldItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

// Stratos
//
@font-face {
    font-family: "Stratos";
    src:url("/public/assets/fonts/stratos/stratosweb-semibold.eot");
    src:url("/public/assets/fonts/stratos/stratosweb-semibold.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/stratos/stratosweb-semibold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

// Toledo
//
@font-face {
    font-family: "Toledo";
    src:url("/public/assets/fonts/toledo/321D52_0_0.eot");
    src:url("/public/assets/fonts/toledo/321D52_0_0.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/toledo/321D52_0_0.woff") format("woff"),
        url("/public/assets/fonts/toledo/321D52_0_0.ttf")  format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Druk
//
@font-face {
    font-family: "Druk";
    src:url("/public/assets/fonts/druk/Druk-Medium-Web.eot");
    src:url("/public/assets/fonts/druk/Druk-Medium-Web.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/druk/Druk-Medium-Web.woff") format("woff"),
        url("/public/assets/fonts/druk/Druk-Medium-Web.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

// BB1
//
@font-face {
    font-family: "BB1";
    src:url("/public/assets/fonts/bb1/321D54_2_0.eot");
    src:url("/public/assets/fonts/bb1/321D54_2_0.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/bb1/321D54_2_0.woff") format("woff"),
        url("/public/assets/fonts/bb1/321D54_2_0.ttf")  format('truetype');
    font-weight: normal;
    font-style: normal;
}

// BB2
//
@font-face {
    font-family: "BB2";
    src:url("/public/assets/fonts/bb2/321D54_3_0.eot");
    src:url("/public/assets/fonts/bb2/321D54_3_0.eot?#iefix") format("embedded-opentype"),
        url("/public/assets/fonts/bb2/321D54_3_0.woff") format("woff"),
        url("/public/assets/fonts/bb2/321D54_3_0.ttf")  format('truetype');
    font-weight: normal;
    font-style: normal;
}

// config
$sans: "Circular-Book", sans-serif;

// name fonts
// for headers
$fontSectra: "Sectra", sans-serif;
$fontFalsterGrotesk: "Falter-Grotesk", sans-serif;
$fontSuperior: "SuperiorTitle-Bold", sans-serif;
$fontSuperiorOblique: "SuperiorTitle-BoldItalic", sans-serif;
$fontStratos: "Stratos", sans-serif;
$fontToledo: "Toledo", sans-serif;
$fontDruk: "Druk", sans-serif;
$fontBB1: "BB1", sans-serif;
$fontBB2: "BB2", sans-serif;

