.mr-slides {
  overflow: hidden;
  padding-top: 75%;
  margin: 1rem 0 2rem 0;
  position: relative;

  @include breakpoint-from($min-width-small) {
    margin: 2rem 0 3.5rem 0;
  }

  @include breakpoint-from($min-width-medium) {
    margin: 3rem 0 4rem 0;
  }

  &.no-margin {
    margin: 0 0 0 0;

    @include breakpoint-from($min-width-small) {
      margin: 0 0 -1rem 0;
    }

    @include breakpoint-from($min-width-medium) {
      margin: 0 0 -4rem 0;
    }
  }

  &.is-ready {
    cursor: grab;
  }

  &.has-not-enough-slides {
    cursor: default;
    padding-top: 66.6666666667%;
  }

  &.is-grabbing {
    cursor: grabbing;
  }
}

.mr-slides__slide {
  border: none;
  color: $black;
  cursor: inherit;
  display: block;
  left: 0;
  opacity: 0;
  padding-top: 66.6666666667%;
  position: absolute;
  top: 0;
  transition: none;
  transform: translateX(0);
  text-decoration: none;
  width: 100%;
  z-index: 0;

  .is-ready & {
    transition: opacity 500ms, transform 500ms $ease-in-out-sine;
  }

  .is-grabbing & {
    transition: none;
  }

  &.is-current {
    opacity: 1;
    transform: translateX(0);
    z-index: 20;
  }

  &.is-next {
    opacity: 1;
    transform: translateX(100%);
    z-index: 10;
  }

  &.is-prev {
    opacity: 1;
    transform: translateX(-100%);
    z-index: 10;
  }
}
