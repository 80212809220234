.social-links {

}

.social-links__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: auto;

  li {
    display: inline-block;
    margin: 0;

    a {
      border: none;
      line-height: normal;
      font-size: 30px;
    }

    // active state
    //
    .current-menu-item {
      a {
        color: blue;
        background-color: red;
      }
    }

  }
}

// social icons
// only define active state
.social-links__list.social {

  li {
    // active state
    //
    &.is-active {
      a {
        color: rgba($black,.2);
      }
    }
  }
}
