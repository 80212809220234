.site-footer {
  border-sizing: border-box;
  padding: 50px 0;
  text-align: center;
}

.site-footer__bottom {
  margin: 25px auto 0 auto;
  max-width: 760px;
}

// nav
.site-footer__menu {
  display: block;
  margin: 2rem 0;
  width: auto;
  z-index: 5555;
}

// ul
.site-footer__menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: auto;

  li {
    display: inline-block;
    margin: 0;
    padding: 0 7px;

    a {
      border: none;
      line-height: normal;
      padding: 7px 0;
      position: relative;
      text-transform: uppercase;

      &:after {
        border-bottom: 2px solid rgba(0,0,0,0);
        content: '';
        display: block;
        transition: all 250ms;
        width: 100%;
      }
    }

    // active state
    //
    &.current-menu-item {
      a {
        color: rgba($black,.2);

        &:after {
          border-bottom: 2px solid currentColor;
        }
      }
    }

    // hover on li
    &:hover {
      a {
        color: $black;

        &:after {
          border-bottom: 2px solid currentColor;
        }
      }
    }

  }
}

// subscribe to mailinglist

.site-footer__menu-subscribe {
  display: inline-block;
  margin: 2rem 0;
  width: auto;
  z-index: 5555;
}

.site-footer__menu-subscribe .site-footer__menu__list {

  li {
    a {
      font-size: $em-base + 4px;
      padding: 0;
      text-transform: none;

      &:after {
        border-bottom: 2px solid currentColor;
      }
    }

    // active state
    //
    &.current-menu-item {
      a {
        color: rgba($black,.2);
      }
    }

  }
}
