// DO NOT EDIT DIRECTLY! Generated by gulp/tasks/iconFont.js from ./gulp/tasks/iconFont/template.scss

@font-face {
  font-family: coffeeklatch-icons;
  src: url("../fonts/coffeeklatch-icons.eot?1475089133054");
  src: url("../fonts/coffeeklatch-icons.eot?1475089133054#iefix") format('embedded-opentype'), url("../fonts/coffeeklatch-icons.woff?1475089133054") format('woff'), url("../fonts/coffeeklatch-icons.ttf?1475089133054") format('truetype'), url("../fonts/coffeeklatch-icons.svg?1475089133054#coffeeklatch-icons") format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon($content) {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'coffeeklatch-icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content;
  }
}

.icon--facebook {
  @include icon("\e001");
}

.icon--instagram {
  @include icon("\e002");
}

.icon--cross {
  @include icon("\e003");
}

.icon--pinterest {
  @include icon("\e004");
}

.icon--menu {
  @include icon("\e005");
}

.icon--twitter {
  @include icon("\e006");
}

.icon--mailinglist {
  @include icon("\e007");
}


