.u-hide {
  display: none;
}

.u-hide-mobile {
  @include breakpoint-up-to ($min-width-small) {
    display: none;
  }
}

.u-hide-tablet {
  @include breakpoint-between ($min-width-small, $min-width-medium) {
    display: none;
  }
}

.u-hide-desktop {
  @include breakpoint-from ($min-width-medium) {
    display: none;
  }
}

.u-hide-xxsmall {

  @include breakpoint-up-to($min-width-xsmall) {
    display: none;
  }
}

.u-hide-xsmall {

  @include breakpoint-between($min-width-xsmall, $min-width-small) {
    display: none;
  }
}

.u-hide-small {

  @include breakpoint-between($min-width-small, $min-width-medium) {
    display: none;
  }
}

.u-hide-medium {

  @include breakpoint-between($min-width-medium, $min-width-large) {
    display: none;
  }
}

.u-hide-large {

  @include breakpoint-from($min-width-large) {
    display: none;
  }
}
