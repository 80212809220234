.interviews-content {
  text-align: center;
}

.interviews-content_title {
  box-sizing: border-box;
  font-size: $em-base + 12px;
  font-weight: bold;
  line-height: $em-base + 15px;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 70%;
}

.interviews-content_cta {
  text-decoration: underline;
  font-size: $em-base + 2px;
  line-height: normal;
  margin: 20px 0 0 0;
  font-weight: normal;
}

