.wrapper {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  width: 100%;

  //outline: 1px solid gray(230);

  &:after {
    display:table;
    clear:both;
    content:"";
  }

  @include breakpoint-from($min-width-small) {
    max-width: $min-width-small;
  }

  @include breakpoint-from($min-width-medium) {
    padding: 0;
    max-width: $min-width-medium;
  }

  @include breakpoint-from($min-width-large) {
    max-width: $min-width-large;
  }
}
