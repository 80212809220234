.top-social-links {
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 30px;
  margin: 0 0 0 25px;
  width: auto;
  z-index: 110;

  @include breakpoint-up-to($min-width-medium) {
    display: none;
  }
}
