// Define your default body text styles here
//
// Please use header elements semantically, not based on their style
// If your header style doesn't fit the default, override it in a module

h1 {
  line-height: 1.5em;
  font-weight: 600;

}

h2 {
  line-height: 1.5em;
  font-weight: 600;

}

h3 {
  line-height: 1.5em;
  font-weight: inherit;

}

h4 {
  line-height: 1.5em;
  font-weight: inherit;

}

h5 {
  line-height: 1.5em;
  font-weight: inherit;

}

p {
  line-height: 1.5em;
}

img {
  display: inline-block;
  // max-width: 1000px;
  max-width: 100%;
}

a {
  border-bottom: 2px solid $black;
  color: $black;
  text-decoration: none;

  &:hover {
    color: rgba($black,.25);
    border-color: rgba($black,.25);
  }
}

blockquote {
  line-height: 1.5em;

  // custom typefaces
  //
  .FalterGrotesk & {
    font-family: $fontFalsterGrotesk;
  }
  .Sectra & {
    font-family: $fontSectra;
  }
  .Superior & {
    font-family: $fontSuperior;
  }
  .SuperiorOblique & {
    font-family: $fontSuperiorOblique;
  }
  .Stratos & {
    font-family: $fontStratos;
  }
  .Toledo & {
    font-family: $fontToledo;
  }
  .Druk & {
    font-family: $fontDruk;
  }
  .BB1 & {
    font-family: $fontBB1;
    font-weight: normal;
  }
  .BB2 & {
    font-family: $fontBB2;
    font-weight: normal;
  }

  p {

    //&:before {
    //  content: "“";
    //}

    //&:after {
    //  content: "”";
    //}
  }

  footer {

  }
}

.caption {
  color: $gray;
  //font-style: italic;
}

.coffeeklatch-logo {
  color: black;
  border-bottom: none;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}
