.site-header {
  position: relative;
  z-index: 10;

  @include breakpoint-from($min-width-medium) {

  }
}

// nav
.site-header__menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 100px;
  position: relative;
}

// ul
.site-header__menu__list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;

  @include breakpoint-up-to($min-width-medium) {
    display: none;
  }

  // li
  .menu-item {
    box-sizing: border-box;
    display: inline-block;
    font-family: $sans;
    margin: 0 35px 0 0;

    @include breakpoint-from($min-width-large) {
      margin: 0 45px 0 0;
    }

    a {
      border-bottom: none;
      color: $black;
      display: block;
      font-size: $em-base + 3px;
      font-weight: inherit;
      text-transform: lowercase;
      text-decoration: none;
      position: relative;

      @include breakpoint-from($min-width-large) {
        font-size: $em-base + 5px;
      }

      &:after {
        border-bottom: 2px solid rgba(0,0,0,0);
        bottom: 30px;
        content: '';
        display: block;
        position: absolute;
        transition: all 250ms;
        width: 100%;
      }
    }

    // make icon for search a little bigger
    &.search {
      a {
        font-size: $em-base - 1px;

        @include breakpoint-from($min-width-large) {
          font-size: $em-base;
        }
      }
    }

    // no margin for last menu-item
    &:last-child {
      margin: 0;
    }

    // active state
    &.current_page_item,
    &.is-active {
      a {
        color: rgba($black,.2);

        &:after {
          border-bottom: 2px solid currentColor;
        }
      }
    }

    // hover on li
    &:hover {
      a {
        &:after {
          border-bottom: 2px solid currentColor;
        }
      }
    }
  }

  &.is-left {
    text-align: left;
  }
  &.is-right {
    text-align: right;
  }
}

// logo
.site-header__logo {
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-bottom: none;
  text-decoration: none;
  letter-spacing: 5px;
  text-transform: uppercase;

  @include breakpoint-from($min-width-small) {
    font-size: 20px;
  }

  @include breakpoint-from($min-width-medium) {
    font-size: 24px;
    margin: 0 45px 0 -32%;
  }

  @include breakpoint-from($min-width-large) {
    margin: 0 45px 0 -28%;
  }

  &:hover {
    color: $black;
  }
}
