.compact-header {
  background-color: white;
  max-height: 50px;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  transition: max-height 512ms $ease-in-out-expo, transform 386ms $ease-in-out-expo;
  top: 0;
  z-index: 100;
  width: 100vw;
  transform: translateY(-50px);
  will-change: transform !important;

  &.is-open {
    max-height: 240px;
    transform: translateY(0);
  }

  .is-scrolling-up & {
    transform: translateY(0);
  }
}


//
// Buttons
//
.compact-header__menu-btn,
.compact-header__close-btn {
  border: none;
  color: rgba($black,.2);
  display: inline-block;
  font-size: 30px;
  padding: 10px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  transition:
    color 250ms,
    opacity 250ms,
    transform 250ms;

  &:hover {
    color: $black;
  }
}

.compact-header__menu-btn {
  opacity: 1;

  .compact-header.is-open & {
    opacity: 0;
    transform: translate(-50%, -50px);
  }
}

.compact-header__close-btn {
  opacity: 0;
  transform: translate(-50%, -50px);

  .compact-header.is-open & {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

//
// Menu list
//
.compact-header__menu__list {
  list-style: none;
  margin: 60px 0 2rem 0;
  opacity: 0;
  padding: 0;
  transition: opacity 300ms;

  .compact-header.is-open & {
    opacity: 1;
  }

  li {
    margin: 1rem 0;

    a {
      border: none;
      color: $black;
      display: inline-block;
      font-size: 1.357rem;
      line-height: 1em;
      position: relative;
      text-decoration: none;
      text-transform: lowercase;

      &:after {
        border-bottom: 2px solid rgba(0,0,0,0);
        bottom: -7px;
        content: '';
        display: block;
        position: absolute;
        transition: all 250ms;
        width: 100%;
      }

      &:hover {
        &:after {
          border-bottom: 2px solid currentColor;
          color: $black;
        }
      }
    }

    &.is-active {

      a {
        color: rgba($black,.2);
        text-transform: lowercase;

        &:after {
          border-bottom: 2px solid currentColor;
        }
      }
    }
  }
}


