.grid-related-posts {
  overflow: hidden;
}

.grid-related-posts__grid {

  @include grid(".grid-post-item", 768px, 2, 25px, 25px);

  @include breakpoint-from($min-width-medium) {
    @include grid(".grid-post-item", 1024px, 3, 30px, 30px);
  }

  @include breakpoint-from($min-width-large) {
    @include grid(".grid-post-item", 1380px, 4, 30px, 30px);
  }

  .grid-post-item {

    &:nth-child(n+4) {

      display: inline-block;

      @include breakpoint-from ($min-width-medium) {
        display: none;
      }

      @include breakpoint-from ($min-width-large) {
        display: inline-block;
      }
    }
  }

}
