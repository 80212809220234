.wp-caption {
  display: block;
  width: auto !important;

  a {
    border: none;
  }
}

.wp-caption-text {
  @extend .caption;
}
