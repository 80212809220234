//
//
// embed-wrapper
// vimeo / iframe responsivenesss
//
.embed-wrapper {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 2em;
  max-width: 100%;
  height: auto;
}

.embed-wrapper iframe,
.embed-wrapper object,
.embed-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
