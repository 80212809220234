.page-content {
  text-align: center;
}

.page-content_hero {
  margin: 3em auto 0 auto;
  max-width: 85vw;
  text-align: center;

  @include breakpoint-from($min-width-small) {
    max-width: 500px;
  }

  @include breakpoint-from($min-width-medium) {
    margin: 3em auto 3.75em auto;
    max-width: 680px;
  }

  @include breakpoint-from($min-width-large) {
    margin: 4em auto 4.75em auto;
    max-width: 770px;
  }
}

// main title
.page-content_hero__title {
  font-size: 1.5rem;
  line-height: 1.38em;
  margin: 0 auto;
  max-width: 230px;
  text-decoration: underline;

  @include breakpoint-from($min-width-medium) {
    font-size: 1.65rem;
  }

  @include breakpoint-from($min-width-large) {
    font-size: 1.857rem; // 26px;
  }

  // custom typefaces
  //
  &.FalterGrotesk {
    font-family: $fontFalsterGrotesk;
  }
  &.Sectra {
    font-family: $fontSectra;
  }
  &.Superior {
    font-family: $fontSuperior;
  }
  &.SuperiorOblique {
    font-family: $fontSuperiorOblique;
  }
  .Stratos & {
    font-family: $fontStratos;
  }
  .Toledo & {
    font-family: $fontToledo;
  }
  .Druk & {
    font-family: $fontDruk;
  }
  .BB1 & {
    font-family: $fontBB1;
    font-weight: normal;
  }
  .BB2 & {
    font-family: $fontBB2;
    font-weight: normal;
  }

  // do you want fries with that?
  //
  &.xl {
    font-size: $em-base + 22px; // 36px;
    line-height: $em-base + 32px; // 46px;
    max-width: 400px;
    text-decoration: none;
  }
}

// subtitle == profession
.page-content_hero_subtitle {
  font-family: $sans;
  font-size: 1.25rem;
  margin-bottom: 0;
  font-weight: normal;

  @include breakpoint-from($min-width-large) {
    font-size: 1.5714rem;
  }
}

// intro
.page-content_hero__intro {
  font-size: 1.2rem;
  line-height: 1.4117em;
  margin: 2em auto 0 auto;

  @include breakpoint-from($min-width-small) {
    font-size: 1.2rem;
  }

  @include breakpoint-from($min-width-medium) {
    font-size: 1.1rem;
    margin: 3em auto 0 auto;
  }

  @include breakpoint-from($min-width-large) {
    font-size: 1.2rem;
    margin: 4em auto 0 auto;
  }
}

// intro (not in hero)
.page-content_intro {
  @extend .page-content_hero__intro;
  display: none;
  text-align: center;

  @include breakpoint-up-to($min-width-medium) {
    display: block;
  }
}

// body
.page-content_body {
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  margin-top: 50px;

  a {

  }

  p {
    margin: 0 auto 1rem auto;
    max-width: 800px;
  }

  h3 {
    font-weight: 600;
    margin: 2rem auto 2rem auto;
    max-width: 550px;
  }

  blockquote {
    margin: 3rem auto 3rem auto;
    font-size: 2rem;

    @include breakpoint-from($min-width-medium) {
      font-size: 2.375rem;
    }
  }

  @include breakpoint-from($min-width-medium) {
    margin-top: 0;
    padding: 0 40px;
  }

  @include breakpoint-from($min-width-large) {
    padding: 0 120px;
  }

}

// read more, action
.page-content__more {
  margin: 3rem auto 4rem auto;
  text-align: center;
}
.page-content__more-link {
  border-bottom: 2px solid white;
  font-size: $em-base + 8px;
  font-weight: bold;
  text-transform: lowercase;
  text-align: center;
  transition: all 250ms;

  &:hover {
    color: $black;
    border-bottom: 2px solid $black;
  }
}

// table
.page-content_credits {
  border: 1px solid $black;
  margin: 3em auto 5.5em auto;
  opacity: 0.1;
  transition: opacity 250ms $ease-in-out-quad;
  max-width: 500px;
  width: 100%;

  tr td {
    border: 1px solid $black;
    padding: 10px;

    &.centered {
      text-align: center;
    }

    &:first-child {
      width: 100px;
    }
  }

  &:hover {
    opacity: 1;
  }
}
