// share
.slideshow-slide__share,
.template__share {
  border-bottom: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: white;
  text-shadow: 0 0 4px rgba(0, 0, 0, .15);
  opacity: 0;

  &:hover {
    color: white;
  }

  .sloth:hover & {
    opacity: 1;
  }
}

// slide
.slideshow-slide {
  @include stretch;
  border-bottom: none;
  display: block;
  margin: 0;

  .sloth__content {
    @include stretch;
    position: absolute !important;
  }

  &:hover {
    text-decoration: none;
    border-bottom: none;

    .slideshow-slide__person {
      color: $black;
    }

    //
    // only on homepage, have the hover effect
    // like we do in the overall grid
    //
    .home-flashes & {

        .slideshow-slide__content {
          opacity: 1;
        }

        // sloth start
        //
        // utilize blendmode
        .sloth__background {
          opacity: .4;
          filter: grayscale(100%);
          mix-blend-mode: multiply;
        }
        // sloth end

    }

  }
}

.slideshow-slide__background {
  @include stretch;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0;
  padding: 66% 0 0 0;
  z-index: 0;
}

.slideshow-slide__content {
  color: $black;
  position: absolute;
  left: 50%;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;
  opacity: 0;

  .is-dark & {
    color: white;
  }
}

.slideshow-slide__title {
  font-size: 1.4rem;
  line-height: 1.1em;
  padding: 0 10px;
  margin: 0;

  @include breakpoint-from($min-width-small) {
    font-size: 1.65rem;
    line-height: 1.2em;
    padding: 0 50px;
  }

  @include breakpoint-from($min-width-medium) {
    font-size: 1.8rem;
    line-height: 1.3em;
    padding: 0 90px;
  }

  @include breakpoint-from($min-width-large) {
    padding: 0 150px;
  }
}

.slideshow-slide__subtitle {
  font-size: 1rem;
  text-decoration: underline;

  @include breakpoint-from($min-width-small) {
    font-size: 1.2rem;
  }

  @include breakpoint-from($min-width-medium) {
    font-size: 1.3rem;
  }
}

.slideshow-slide__person {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 102.5%;
}
