.grid-interviews {
  overflow: hidden;
  margin-top: 40px;

  @include breakpoint-from($min-width-medium) {
    margin-top: 60px;
  }

  @include breakpoint-from($min-width-large) {
    margin-top: 50px;
  }
}

.grid-interviews__grid {
  @include grid(".grid-post-item", 768px, 2, 25px, 25px);

  @include breakpoint-from($min-width-medium) {
    @include grid(".grid-post-item", 1024px, 3, 30px, 30px);
  }

  @include breakpoint-from($min-width-large) {
    @include grid(".grid-post-item", 1380px, 4, 30px, 30px);
  }

  .grid-post-item {

    &:nth-child(n+7) {

      body.home & {

        @include breakpoint-up-to($min-width-large) {
          display: none;
        }
      }
    }
  }
}
