.the-sound-of-c {
  //background-image: url('../images/img-soundofc.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-bottom: none;
  top: 20px;
  height: 50px;
  position: fixed;
  left: 20px;
  text-decoration: none;
  width: 50px;
  z-index: 110;

  background:
    linear-gradient(transparent, transparent),
    url("../images/sound-of-c/one.svg");

  animation-name: animate-c;
  animation-iteration-count: infinite;
  animation-duration: 750ms;

  //
  // Don't show C on interview detail
  //
  body.single-post & {
    display: none;
  }
}

.the-sound-of-c-one {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0 auto 20px auto;
  height: 50px;
  text-indent: -9999px;
  width: 50px;

  &.rand_1 {
    background-image: url("../images/sound-of-c/one.png");
    background-image:
      linear-gradient(transparent, transparent),
      url("../images/sound-of-c/one.svg");
  }
  &.rand_2 {
    background-image: url("../images/sound-of-c/two.png");
    background-image:
      linear-gradient(transparent, transparent),
      url("../images/sound-of-c/two.svg");
  }
  &.rand_3 {
    background-image: url("../images/sound-of-c/three.png");
    background-image:
      linear-gradient(transparent, transparent),
      url("../images/sound-of-c/three.svg");
  }
  &.rand_4 {
    background-image: url("../images/sound-of-c/four.png");
    background-image:
      linear-gradient(transparent, transparent),
      url("../images/sound-of-c/four.svg");
  }
  &.rand_5 {
    background-image: url("../images/sound-of-c/five.png");
    background-image:
      linear-gradient(transparent, transparent),
      url("../images/sound-of-c/five.svg");
  }
}
