@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}


@keyframes animate-c {
    0%   {
      background: url("../images/sound-of-c/one.png");
      background:
        linear-gradient(transparent, transparent),
        url("../images/sound-of-c/one.svg");
    }
    25%   {
      background: url("../images/sound-of-c/two.png");
      background:
        linear-gradient(transparent, transparent),
        url("../images/sound-of-c/two.svg");
    }
    50%   {
      background: url("../images/sound-of-c/three.png");
      background:
        linear-gradient(transparent, transparent),
        url("../images/sound-of-c/three.svg");
    }
    75%   {
      background: url("../images/sound-of-c/four.png");
      background:
        linear-gradient(transparent, transparent),
        url("../images/sound-of-c/four.svg");
    }
    100%   {
      background: url("../images/sound-of-c/five.png");
      background:
        linear-gradient(transparent, transparent),
        url("../images/sound-of-c/one.svg");
    }
}
