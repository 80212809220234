.subscribe-form {
	//background-color: rgba($black,.1);
	margin: 4rem auto 0 auto;

	label {
		@extend .form-label;
		font-size: 1.25em;
		font-weight: 200;
		text-transform: none;
		margin: 0 0 20px 0;
	}

	input[type="text"],
	input[type="email"],
	input[type="number"] {
		@extend .form-input;
		font-family: sans-serif;
		margin: 0 0 20px 0;
		padding: 10px 15px;
		text-align: center;
		width: 200px;
	}

	select,
	option {
		font-family: sans-serif;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
	}

}

.subscribe-form-half {
	display: inline-block;
	width: 48%;

	&.left {
		margin-right: 37px;
	}
}
