.grid-home-actions {
  overflow: hidden;


  // sloth start
  //
  // utilize blendmode
  //.sloth__background {
  //  opacity: .4;
  //  filter: grayscale(100%);
  //  mix-blend-mode: multiply;
  //}
  // sloth end

}

.grid-home-actions__grid {

  @include breakpoint-from($min-width-xsmall) {
    @include grid(".grid-post-item", 768px, 2, 25px, 25px);
  }

  @include breakpoint-from($min-width-medium) {
    @include grid(".grid-post-item", 1024px, 4, 30px, 30px);
  }

  @include breakpoint-from($min-width-large) {
    @include grid(".grid-post-item", 1380px, 4, 30px, 30px);
  }
}
