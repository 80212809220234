.bullet-nav {
  //bottom: 0;
  position: absolute;
  text-align: center;
  top: 90.8%;
  width: 100%;
  z-index: 100;

  &.has-not-enough-slides {
    display: none;
  }
}

.bullet-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

.bullet-nav__item {
  display: inline-block;
  margin: 0 4px;

  a {
    border: none;
    box-sizing: border-box;
    padding: 2px;
    display: block;

    &.is-current {
      color: rgba($black,.4);
    }

    &:hover {
      color: $black;
    }
  }

  &:last-child {
    margin: 0 0 0 4px;

    a {
      padding: 2px 0  2px  2px;
    }
  }
}
