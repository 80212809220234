html {
  padding: 0 !important;
  margin: 0;
  font-size: $em-base; // 14px;
  line-height: $em-base + 6px; // 20px;

  @include breakpoint-from($min-width-medium) {
    font-size: $em-base + 1px; // 15px;
    line-height: $em-base + 8px; // 22px;
  }

  @include breakpoint-from($min-width-large) {
    font-size: $em-base + 2px; // 16px;
    line-height: $em-base + 10px; // 24px;
  }

}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  font-family: $sans;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

// plugin classname
.icl_post_in_other_langs {
  opacity: .25;
  border: 2px solid $black;
  display: inline-block;
  font-size: $em-base;
  padding: 10px 15px;
}
